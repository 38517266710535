<template>
  <div id="app" @click="play_optimista">
    <PreloaderWeb :enable="enabled_preloader" @play="play_optimista"></PreloaderWeb>
    <NavbarMain></NavbarMain>
    <transition name="zoom" mode="out-in">
      <router-view>
      </router-view>
    </transition>
    <audio class="is-hidden" ref="audio" controls>
      <source src="./assets/optimista.mp3">
    </audio>
  </div>
</template>

<script>
  import PreloaderWeb from './components/PreloaderWeb.vue';
  import NavbarMain from './components/NavbarMain.vue';
  import './assets/d-din/style.css';

  export default {
    name: 'App',
    components: {
      PreloaderWeb,
      NavbarMain
    },
    data() {
      return {
        enabled_preloader: false,
        currentTime: 0,
        change: true,
        text: [{
            time: "00:05",
            line: "Dneska ráno nechce se mi vlastně ani trochu vstávat"
          },
          {
            time: "00:09",
            line: "Oči mám zalepený plný ospalků"
          },
          {
            time: "00:14",
            line: "Včera chvíli po poledni přišla z hora blbá zpráva"
          },
          {
            time: "00:18",
            line: "Hučí mi v hlavě z toho jak skládám tu mozaiku"
          },
          {
            time: "00:23",
            line: "Počítám kolik let je přede mnou a kolik za mnou"
          },
          {
            time: "00:27",
            line: "co zbytečnýho řeklo se, neřeklo se"
          },
          {
            time: "00:31",
            line: "Po těžký kocovině nechám svoji hlavu schladnout"
          },
          {
            time: "00:36",
            line: "Jistý je, že se země, i beze mě, točí po ose"
          },
          {
            time: "00:41",
            line: "Co na tom jakej je plán, kolik let ještě tu mám"
          },
          {
            time: "00:45",
            line: "Co na tom oč tu běží, vždyť na tom nezáleží"
          },
          {
            time: "00:49",
            line: "Co na tom jakej je plán, kolik let ještě tu mám"
          },
          {
            time: "00:53",
            line: "Co na tom co se chystá, jsem věčný optimista"
          },
          {
            time: "00:58",
            line: "jsem věčný optimista"
          },
          {
            time: "01:00",
            line: "Možná maj tam nahoře se mnou trochu jiný plány"
          },
          {
            time: "01:04",
            line: "Že prej jsme tady z nějakýho důvodu"
          },
          {
            time: "01:09",
            line: "Tak svatej Petře ještě neotvírej brány"
          },
          {
            time: "01:14",
            line: "I s utrženým uchem budu chodit pro vodu"
          },
          {
            time: "01:17",
            line: "Éj"
          },
          {
            time: "01:18",
            line: "Co na tom jakej je plán, kolik let ještě tu mám"
          },
          {
            time: "01:22",
            line: "Co na tom oč tu běží, vždyť na tom nezáleží"
          },
          {
            time: "01:27",
            line: "Co na tom jakej je plán, kolik let ještě tu mám"
          },
          {
            time: "01:32",
            line: "Co na tom co se chystá, jsem věčný optimista"
          },
          {
            time: "01:36",
            line: "Nanananana nanananana"
          },
          {
            time: "01:41",
            line: "nanananana nananana"
          },
          {
            time: "01:45",
            line: "nanananana nanananana"
          },
          {
            time: "01:49",
            line: "nanananana"
          },
          {
            time: "01:51",
            line: "jsem věčnej optimista!"
          },
          {
            time: "01:53",
            line: '<h1><svg height="100" stroke="white" stroke-width="1" class="text-line" width="800"><text x="50%" dominant-baseline="middle" text-anchor="middle" y="50%">UNPREDICTABLES</text></svg></h1>'
          }
        ]
      }
    },
    created() {
      if (this.$route.name == "Domov") {
        this.enabled_preloader = true;
      }
    },
    computed: {
      currentLine() {
        const currentText = this.text.find((item, index) => {
          const startTime = this.parseTime(item.time);
          const nextTime = this.text[index + 1] ? this.parseTime(this.text[index + 1].time) : Infinity;
          this.change = !this.change;
          return this.currentTime >= startTime && this.currentTime < nextTime;
        });

        return currentText ? currentText.line : '<h1><svg height="100" stroke="white" stroke-width="1" class="text-line" width="800"><text x="50%" dominant-baseline="middle" text-anchor="middle" y="50%">Jsme optimisti</text></svg></h1>';
      }
    },
    methods: {
      play_optimista: function () {
        this.$refs.audio.play();
        this.$refs.audio.volume = 0.05;
        this.$refs.audio.addEventListener('timeupdate', () => this.currentTime = this.$refs.audio.currentTime.toFixed() - 1)
      },
      parseTime(time) {
        const parts = time.split(":");
        const minutes = parseInt(parts[0], 10);
        const seconds = parseInt(parts[1], 10);
        return minutes * 60 + seconds;
      }
    }
  }
</script>
<style>
  .textarea,
  .input {
    background-color: transparent !important;
    border: 1px solid white !important;
    border-radius: 0px !important;
    color: white !important;
  }
html {
  
    background-color: #141414 !important;
}
  body {
    font-family: 'D-DIN Exp Regular', Arial, sans-serif !important;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #aaa !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa !important;
  }

  ::placeholder {
    color: #aaa !important;
  }

  .zoom-enter-active {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoom-leave-active {
    -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }
  }

  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }
  }

  /*img {
  width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    z-index:0;
}*/
</style>