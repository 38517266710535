<template>
    <div>
        <div class="video-comp">

            <div class="overlay"></div>

            <div class="cnt">
                <div class="centered-text">
                    <transition name="coolslide" mode="out-in">
                        <div :key="$parent.currentLine" class="text-line" v-html="$parent.currentLine"></div>
                    </transition>
                </div>

            </div>
            <video loop autoplay muted>
                <source src="../assets/mountains.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="articles">
            <div class="columns is-multiline">
                <div class="column is-one-quarter" v-for="article in articles" :key="article.id">
                    <div class="card">
                        <div class="card-image">
                            <figure class="image is-4by3" v-if="article.images">
                                <img :src="article.images[0].source_url" :alt="article.title.rendered">
                            </figure>
                        </div>
                        <div class="card-content">
                            <div class="media">
                                <div class="media-content">
                                    <p class="title is-4">{{article.title.rendered}}</p>
                                </div>
                            </div>

                            <div class="content">
                                <time datetime="2016-1-1">{{ get_date(article.date) }}</time> -
                                {{ article.author}}
                            </div>
                        </div>
                        <footer class="card-footer">
                            <router-link :to="'/clanek/' + article.slug" class="card-footer-item">Zobrazit</router-link>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
        <FooterMain></FooterMain>
    </div>
</template>

<script>
    import FooterMain from "@/components/FooterMain.vue";
    import axios from "axios";
    export default {
        name: 'MainPage',
        components: {
            FooterMain
        },
        data() {
            return {
                articles: []
            }
        },
        created() {
            document.title = "Unpredictables";
            this.load_articles()
        },
        methods: {
            load_articles: function () {
                var url = "https://unpredictabl.es/wp-json/wp/v2/posts";
                var self = this;
                axios.get(url).then(function (response) {
                    self.articles = response.data;
                    self.articles.forEach((article) => {
                        self.load_author(article._links.author[0].href, (nm) => article.author =
                            nm);
                        self.load_media(article._links["wp:attachment"][0].href, (nm) => article
                            .images = nm)
                        console.log(article.images)
                    });
                });
            },
            load_media: function (url, obj) {
                var self = this;
                axios.get(url).then(function (response) {
                    var returnarr = [];
                    response.data.forEach(element => {
                        if (element["media_type"] == "image")
                            returnarr.push(element);

                        obj(returnarr);
                    });
                    self.loaded = true;
                });
            },
            get_date: function (date) {
                var date_publish = new Date(date);
                var options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                };
                return date_publish.toLocaleDateString("cs-CZ", options);
            },
            load_author: function (url, author) {
                axios.get(url).then(function (response) {

                    author(response.data.name);
                });
            },
            load_tags: function (id) {
                var url = "https://unpredictabl.es/wp-json/wp/v2/tags?post=" + id
                var self = this;
                axios.get(url).then(function (response) {
                    self.tags = response.data;
                });
            },
            load_categories: function (id) {
                var url = "https://unpredictabl.es/wp-json/wp/v2/categories?post=" + id
                var self = this;
                axios.get(url).then(function (response) {
                    self.categories = response.data;
                });
            },
        }
    }
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
    /* Přechod pro zobrazení */
    .show-enter-active,
    .show-leave-active {
        transition: opacity 0.5s;
    }

    .show-enter,
    .show-leave-to {
        opacity: 0;
    }

    /* Přechod pro skrytí */
    .hide-enter-active,
    .hide-leave-active {
        transition: opacity 0.5s;
    }

    .hide-enter,
    .hide-leave-to {
        opacity: 1;
    }

    .articles {
        width: calc(100% - 100px);
        min-height: 400px;
        background-color: rgb(15, 15, 15);
        margin-left: 50px;
        margin-right: 50px;
        margin-top: -80px;
        z-index: 800;
        position: relative;
        padding: 25px;
    }

    .video-comp {
        width: 100%;
        max-height: 80vh;
        position: relative;
        video {
  
        width: 100%;
}
        overflow: hidden;
    }

    .cnt {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: absolute;
        width: 100%;
        padding:100px;
        z-index: 700;
    }

    .overlay {
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.8575805322128851) 0%, rgba(0, 0, 0, 0) 39%);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }

    .centered-text {
        text-align: center;
        font-size: 3rem;
        font-weight: 600;
        
        h1 {
        font-size: 5rem;
        margin-left: -4rem;
        margin-right: -4rem;
                    stroke-dasharray: 200;
        stroke-dashoffset: 500;
        color: transparent;
        animation: dash 25s linear infinite;
        fill: transparent;
        }
    }
    @keyframes dash {
        0% {
            stroke-dashoffset: 500;
        }

        50% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: 500;
        }
    }

    .coolslide-enter-active {
        animation: slide-in-blurred-right 0.4s;
    }

    .coolslide-leave-to {
        animation: slide-out-blurred-left 0.4s;
    }


    @keyframes slide-in-blurred-right {
        0% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }


    @keyframes slide-out-blurred-left {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
            opacity: 0;
        }
    }
</style>