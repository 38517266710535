<template>
    <div>
    <div class="UnPr_Article" v-if="loaded">
        <b-modal v-if="images.length >= 1" v-model="gallery_active" has-modal-card trap-focus :destroy-on-hide="true"
            aria-role="dialog" aria-label="Images" close-button-aria-label="Close" aria-modal>
            <template>
                <b-carousel :indicator-inside="false">
                    <b-carousel-item v-for="(image, i) in images" :key="i">
                        <b-image class="image" :src="image.source_url"></b-image>
                    </b-carousel-item>

                </b-carousel>
            </template>
        </b-modal>
        <div class="columns is-mobile is-vcentered A M">
            <div class="column is-narrow">
                <avatar :username="author.name" :size="64" :src="author.profile"></avatar>
            </div>
            <div class="column is-narrow">
                <p class="UnPrAr_Lvl1">
                    {{author.name}}
                </p>
                <p class="UnPrAr_Lvl2">
                    {{meta.date}} <span class="red">•</span> {{meta.readtime}}

                </p>
            </div>
            <div class="column"></div>
            <div class="column is-hidden-mobile is-narrow has-text-right">

                <b class="has-text-grey">Další články v rubrikách </b>
                <router-link class="has-text-light" v-for="(category, i) in categories" :key="i"
                    :to="'/kategorie/' + category.slug">{{category.name}}&nbsp;&nbsp;</router-link><br>
                <router-link v-for="(tag, i) in tags" :key="i" :to="'/stitek/' + tag.slug">#{{tag.slug}}&nbsp;&nbsp;
                </router-link>
            </div>
        </div>
        <div class="is-hidden-tablet A">
            <b class="has-text-grey">Další články v rubrikách </b>
            <router-link class="has-text-light" v-for="(category, i) in categories" :key="i"
                :to="'/kategorie/' + category.slug">{{category.name}}&nbsp;&nbsp;</router-link><br>
            <router-link v-for="(tag, i) in tags" :key="i" :to="'/stitek/' + tag.slug">#{{tag.slug}}&nbsp;&nbsp;
            </router-link>
            <br><br><br>
            <br>
        </div>
        <div class="UnPrAr_HeaderContainer">
            <div class="UnPrAr_HeaderShadow">
                <svg height="100" stroke="white" stroke-width="1" class="text-line" width="100%"><text x="50%"
                        dominant-baseline="middle" text-anchor="middle" y="50%">{{title}}</text></svg>
            </div>
            <div class="A">
                <h1 class="UnPrAr_Header">{{title}}</h1>
            </div>

        </div>
        <div class="UnPrAr_Images A" v-if="images.length >= 1">
            <div class="columns" @click="gallery_active = true">
                <div class="column ">
                    <div class="UnPrAr_ImageMain" :style="{ backgroundImage: 'url('+images[0].source_url+')' }"></div>
                </div>
                <div class="column is-4" v-if="images.length >= 2">

                    <div class="UnPrAr_ImageSecondaryH" :style="{ backgroundImage: 'url('+images[1].source_url+')' }"
                        v-if="images.length == 2"></div>
                    <div class="UnPrAr_ImageSecondary" :style="{ backgroundImage: 'url('+images[1].source_url+')' }"
                        v-if="images.length >= 3"></div>
                    <div class="UnPrAr_ImageTertiary" :style="{ backgroundImage: 'url('+images[2].source_url+')' }"
                        v-if="images.length >= 3">
                        <div class="UnPrArIt_Text" v-if="images.length > 4">;
                            <p>

                                Další<span v-if="images.length > 7">ch</span>

                                <span class="red"> {{images.length - 3}} </span>
                                <span v-if="images.length > 7">fotek</span>
                                <span v-if="images.length <= 7">fotky</span>

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content A wp-block-group" v-html="text"></div>
        <div class="A">
            <hr>
        </div>
        <div class="second_level A">
            <h2 class="title has-text-white">Komentáře</h2>
            <div v-if="comments.length > 0">
                <article class="media" v-for="(comment, i) in comments" :key="i">
                    <figure class="media-left">
                        <avatar :username="comment['author_name']" :src="comment.author_avatar_urls['96']" :size="64">
                        </avatar>
                    </figure>
                    <div class="media-content">
                        <div class="content">

                            <strong>{{comment['author_name']}}</strong> <small>{{get_date_czech(comment.date)}}</small>
                            <br>
                            <p v-html="comment.content.rendered">
                            </p>

                        </div>
                        <nav class="level is-mobile">
                            <div class="level-left">
                                <a class="level-item">
                                    <span class="icon is-small"><i class="fas fa-reply"></i></span>
                                </a>
                                <a class="level-item">
                                    <span class="icon is-small"><i class="fas fa-retweet"></i></span>
                                </a>
                                <a class="level-item">
                                    <span class="icon is-small"><i class="fas fa-heart"></i></span>
                                </a>
                            </div>
                        </nav>
                    </div>

                </article>
            </div>
            <br>
            <article class="media">
                <figure class="media-left">
                    <avatar username="Nepřihlášeno"
                        src="https://unpredictabl.es/wp-content/uploads/2023/01/obrazek_2023-01-09_222715952.png"
                        :size="64"></avatar>
                </figure>
                <div class="media-content">
                    <div class="field">
                        <p class="control">
                            <b-input type="textarea" v-model="comment.text" placeholder="Přidejte komentář" />
                        </p>
                    </div>
                    <nav class="level">
                        <div class="level-left">

                            <div class="level-item">
                                <div class="columns is-mobile is-multiline is-vcentered">
                                    <div class="column is-narrow">
                                        <b-input v-model="comment.name" placeholder="Vaše jméno*" />
                                    </div>
                                    <div class="column is-narrow">
                                        <b-input type="email" v-model="comment.email" placeholder="Váš email*" />
                                    </div>
                                    <div class="column is-narrow">
                                        {{tester.first}} + {{tester.second}} =
                                    </div>
                                    <div class="column is-narrow">
                                        <b-input v-model="tester.result" class="ll" />
                                    </div>

                                    <div class="column"></div>
                                </div>
                            </div>

                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <a class="button is-primary is-outlined" @click="send_comment">Odeslat</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </article>
        </div></div>
        <footer-main></footer-main>
    </div>
</template>

<style scoped lang="scss">
    strong {
        color: white;
    }

    .button {
        border-radius: 0px;

    }

    .second_level {
        margin-top: -24px;
    }

    hr {
        border-top: 1px solid white;
    }

    .ll {
        width: 32px;
    }

    .UnPrArIt_Text {
        backdrop-filter: blur(10px) brightness(.5);
        width: 100%;
        height: 100%;
        font-size: 2rem;
        display: table;
        cursor: pointer;
        user-select: none;
    }

    .UnPrAr_ImageTertiary:hover {
        opacity: .8;
    }

    .UnPrArIt_Text p {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }

    .UnPrAr_ImageSecondary {
        width: 100%;
        height: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

    }

    .UnPrAr_ImageSecondaryH {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

    }

    .UnPrAr_ImageTertiary {

        margin-top: 25px;
        width: 100%;
        height: 125px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: all .2s ease;
    }

    .UnPrAr_ImageMain {
        width: 100%;
        height: 350px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .is-active .al img {
        filter: grayscale(0%);
    }

    .al img {
        filter: grayscale(100%);
    }

    .M {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .UnPrAr_HeaderContainer {
        position: relative;
        margin-top: -55px;
        margin-bottom: -55px;

    }

    .UnPrAr_Header {
        font-size: 3rem;
        font-family: 'D-DIN Exp DINExp-Bold', Arial, sans-serif;
        position: absolute;
        bottom: 4rem;
    }

    .UnPrAr_HeaderShadow {
        font-size: 7rem;
        opacity: .2;
        margin-left: -4rem;
        margin-right: -4rem;
        stroke-dasharray: 200;
        stroke-dashoffset: 500;
        color: transparent;
        animation: dash 25s linear infinite;
        fill: transparent;
        font-family: 'D-DIN Exp DINExp-Bold', Arial, sans-serif;

    }

    @keyframes dash {
        0% {
            stroke-dashoffset: 500;
        }

        50% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: 500;
        }
    }

    .red {
        color: red;
    }

    .UnPr_Article{
        max-width: 1400px;
        margin: 0 auto;
        padding: 25px;
        padding-top: 100px;
        .A {
            padding-top: 20px;
        }
    }

    .UnPrAr_Lvl1 {
        opacity: .5;
    }
</style>

<script>
    import Avatar from 'vue-avatar';
    import axios from 'axios';
    import FooterMain from '@/components/FooterMain.vue';
    export default {
        name: 'veShopCategoryView',
        components: {
            Avatar,
            FooterMain
        },
        props: {
            slug: String
        },
        title: "",
        data() {
            return {
                id: 0,
                loaded: false,
                gallery_active: false,
                author: {
                    name: "",
                    profile: ""
                },
                meta: {
                    date: "",
                    readtime: ""
                },
                title: "",
                images: [],
                categories: [],
                tags: [],
                text: "",
                comments: [],
                comment: {
                    name: "",
                    email: "",
                    text: ""
                },
                tester: {
                    first: this.get_random(1, 9),
                    second: this.get_random(1, 9),
                    result: ""
                }
            }

        },
        methods: {
            get_random(min, max) {
                return Math.floor(Math.random() * (max - min)) + min;
            },
            send_comment: function () {
                var url = "https://unpredictabl.es/wp-json/wp/v2/comments";
                var self = this;
                var can_go = true;

                if (this.comment.name == "") {
                    can_go = false;
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        queue: false,
                        message: `Vyplňte jméno!`,
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                }
                if (this.comment.email == "") {
                    can_go = false;
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        queue: false,
                        message: `Vyplňte email!`,
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                }
                if (this.comment.text == "") {
                    can_go = false;
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        queue: false,
                        message: `Vyplňte komentář!`,
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                }
                if ((Number(this.tester.first) + Number(this.tester.second)) != Number(this.tester.result)) {
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        queue: false,
                        message: `Vyplňte hádanku!`,
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                    can_go = false;
                }
                if (can_go) {
                    axios.post(url, {
                            post: this.id,
                            author_name: this.comment.name,
                            author_email: this.comment.email,
                            content: this.comment.text,
                        }).then(function () {
                            self.load_comments();
                            self.comment.name = "";
                            self.comment.email = "";
                            self.comment.text = "";
                            self.$buefy.snackbar.open({
                                duration: 5000,
                                queue: false,
                                message: `Komentář byl odeslán!`,
                                position: 'is-bottom',
                                type: 'is-info'
                            });


                        })
                        .catch(function (error) {
                            self.$buefy.snackbar.open({
                                duration: 5000,
                                queue: false,
                                message: error.message,
                                position: 'is-bottom',
                                type: 'is-danger'
                            });
                        });
                }
            },
            load_comments: function () {
                var url = "https://unpredictabl.es/wp-json/wp/v2/comments?post=" + this.id;
                var self = this;
                axios.get(url).then(function (response) {
                    self.comments = response.data;
                });

            },
            reading_time: function () {
                var text = this.text;
                const wpm = 225;
                const words = text.trim().split(/\s+/).length;
                return Math.ceil(words / wpm);
            },
            get_date_czech: function (date) {
                var date_r = new Date(date);
                var options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                };
                return date_r.toLocaleDateString("cs-CZ", options);
            },
            load_article: function () {
                var url = "https://unpredictabl.es/wp-json/wp/v2/posts?slug=" + this.slug;
                var self = this;
                axios.get(url).then(function (response) {
                    self.id = response.data[0].id;
                    self.title = response.data[0].title.rendered;
                    document.title = self.title + " - Unpredictables";
                    var date_publish = new Date(response.data[0].date);
                    var options = {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    };
                    self.meta.date = date_publish.toLocaleDateString("cs-CZ", options);
                    self.load_author(response.data[0]._links.author[0].href);
                    self.text = response.data[0].content.rendered;
                    var length_read = self.reading_time();
                    var suffix = " minuta čtení";
                    if (length_read > 1)
                        suffix = " minuty čtení";
                    if (length_read >= 5)
                        suffix = " minut čtení";
                    self.meta.readtime = length_read + suffix;
                    if (response.data[0]._links["wp:attachment"][0].href != undefined)
                        self.load_media(response.data[0]._links["wp:attachment"][0].href);

                    if (response.data[0].tags.length > 0)
                        self.load_tags(response.data[0].id);

                    if (response.data[0].categories.length > 0)
                        self.load_categories(response.data[0].id);

                    self.load_comments();
                });
            },
            load_author: function (url) {
                var self = this;
                axios.get(url).then(function (response) {
                    self.author.name = response.data.name;
                    self.author.profile = response.data.avatar_urls["96"];
                });
            },
            load_tags: function (id) {
                var url = "https://unpredictabl.es/wp-json/wp/v2/tags?post=" + id
                var self = this;
                axios.get(url).then(function (response) {
                    self.tags = response.data;
                });
            },
            load_categories: function (id) {
                var url = "https://unpredictabl.es/wp-json/wp/v2/categories?post=" + id
                var self = this;
                axios.get(url).then(function (response) {
                    self.categories = response.data;
                });
            },
            load_media: function (url) {
                var self = this;
                axios.get(url).then(function (response) {

                    response.data.forEach(element => {

                        if (element["media_type"] == "image")
                            self.images.push(element)
                    });
                    self.loaded = true;
                });
            },
        },
        mounted() {
            this.load_article();
        }
    }
</script>