<template>
  <div class="preloader" v-if="enable">
    <div class="preloader-body"></div>
    <div class="preloader-text">
      <svg height="100" stroke="white" stroke-width="1" class="text-line" width="100%"><text x="50%"
          dominant-baseline="middle" text-anchor="middle" y="50%">The Base of Moazagotl</text></svg>
      
    </div>
    <div class="preloader-logo">
      <div>
 <svg width="100%" height="100%" viewBox="0 0 292 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
  <rect id="Artboard2" x="-0" y="0" width="291.479" height="37.483" style="fill:none;"/>
  <path class="symbol-1"  d="M18.923,22.289c-0,1.062 -0.203,1.777 -0.609,2.146c-0.405,0.369 -1.146,0.553 -2.223,0.553l-7.211,0c-1.076,0 -1.817,-0.184 -2.223,-0.553c-0.406,-0.369 -0.608,-1.084 -0.608,-2.146l-0,-8.715l2.654,0l0,9.268l7.565,0l0,-9.268l2.655,0l-0,8.715Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-2"  d="M37.372,24.988l-1.813,0l-8.384,-7.056c-0.354,-0.295 -0.671,-0.605 -0.951,-0.929c0.074,0.737 0.11,1.32 0.11,1.747l0,6.238l-2.389,0l0,-11.414l1.814,0l8.03,6.725c0.575,0.486 1.01,0.899 1.305,1.238c-0.074,-1.061 -0.111,-1.718 -0.111,-1.968l0,-5.995l2.389,0l0,11.414Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-3"  d="M54.683,18.33c-0,1.475 -0.856,2.212 -2.566,2.212l-7.167,-0l-0,4.446l-2.61,0l-0,-11.414l9.777,0c1.71,0 2.566,0.737 2.566,2.212l-0,2.544Zm-2.566,-0.664l-0,-1.216c-0,-0.384 -0.111,-0.634 -0.332,-0.752c-0.162,-0.104 -0.45,-0.155 -0.863,-0.155l-8.582,-0l-0,3.03l8.582,0c0.413,0 0.701,-0.051 0.863,-0.155c0.221,-0.118 0.332,-0.368 0.332,-0.752Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-4"  d="M71.496,24.988l-3.805,0l-4.601,-4.313l-2.522,-0l0,4.313l-2.61,0l-0,-11.414l9.777,0c1.711,0 2.566,0.737 2.566,2.212l0,2.677c0,1.106 -0.442,1.791 -1.327,2.057c-0.339,0.103 -1.254,0.155 -2.743,0.155l5.265,4.313Zm-3.761,-7.167l0,-1.371c0,-0.384 -0.111,-0.634 -0.332,-0.752c-0.162,-0.104 -0.449,-0.155 -0.862,-0.155l-8.583,-0l-0,3.185l8.583,0c0.413,0 0.7,-0.051 0.862,-0.155c0.221,-0.118 0.332,-0.368 0.332,-0.752Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-5"  d="M86.691,24.988l-11.79,0l-0,-11.414l11.679,0l0,1.947l-9.025,-0l0,2.544l5.265,-0l-0,1.946l-5.265,0l0,2.831l9.136,0l-0,2.146Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-6"  d="M104.655,19.215c0,2.212 -0.84,3.841 -2.521,4.888c-0.959,0.59 -2.522,0.885 -4.69,0.885l-6.658,0l0,-11.414l7.344,0c1.976,0 3.524,0.443 4.645,1.327c1.254,0.974 1.88,2.411 1.88,4.314Zm-2.742,-0.044c-0,-2.375 -1.254,-3.562 -3.761,-3.562l-4.711,0l-0,7.233l3.428,0c1.622,0 2.802,-0.206 3.539,-0.619c1.003,-0.56 1.505,-1.578 1.505,-3.052Z" style="fill:#fff;fill-rule:nonzero;"/>
  <rect class="symbol-7"  x="108.714" y="13.574" width="2.654" height="11.414" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-8"  d="M128.594,22.289c0,1.062 -0.202,1.777 -0.608,2.146c-0.405,0.369 -1.146,0.553 -2.223,0.553l-6.813,0c-1.077,0 -1.818,-0.184 -2.223,-0.553c-0.406,-0.369 -0.608,-1.084 -0.608,-2.146l-0,-6.016c-0,-1.062 0.202,-1.777 0.608,-2.146c0.405,-0.369 1.146,-0.553 2.223,-0.553l6.813,0c1.357,0 2.197,0.317 2.522,0.951c0.206,0.369 0.309,1.313 0.309,2.832l-2.654,0.42l-0,-2.168l-7.167,0l0,7.233l7.167,0l-0,-2.455l2.654,0.553l0,1.349Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-9"  d="M143.845,15.609l-5.265,0l0,9.379l-2.654,0l0,-9.379l-5.242,0l-0,-2.035l13.161,0l0,2.035Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-10" d="M158.111,24.988l-2.986,0l-1.372,-2.499l-7.233,-0l-1.327,2.499l-2.655,0l6.459,-11.414l2.566,0l6.548,11.414Zm-5.464,-4.557l-2.455,-4.648l-2.443,0l-2.632,4.648l7.53,0Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-11" d="M173.411,22.754c-0,1.489 -1.121,2.234 -3.363,2.234l-9.069,0l0,-11.414l9.777,0c1.711,0 2.566,0.737 2.566,2.212l0,1.394c0,1.12 -0.656,1.762 -1.968,1.924c1.371,0.177 2.057,0.907 2.057,2.19l-0,1.46Zm-2.544,-5.419l-0,-1.084c-0,-0.546 -0.406,-0.819 -1.217,-0.819l-8.671,0l0,2.721l8.671,-0c0.811,-0 1.217,-0.273 1.217,-0.818Zm0.066,4.578l0,-0.862c0,-0.428 -0.099,-0.704 -0.298,-0.83c-0.199,-0.125 -0.528,-0.188 -0.985,-0.188l-8.671,0l0,2.898l8.671,-0c0.457,-0 0.786,-0.063 0.985,-0.188c0.199,-0.125 0.298,-0.402 0.298,-0.83Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-12" d="M188.938,24.988l-11.215,0l0,-11.414l2.655,0l-0,9.268l8.56,0l0,2.146Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-13" d="M203.813,24.988l-11.79,0l-0,-11.414l11.679,0l0,1.947l-9.025,-0l0,2.544l5.265,-0l-0,1.946l-5.265,0l0,2.831l9.136,0l-0,2.146Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-14" d="M220.287,22.289c0,1.062 -0.206,1.777 -0.619,2.146c-0.413,0.369 -1.158,0.553 -2.234,0.553l-7.057,0c-1.165,0 -1.931,-0.206 -2.3,-0.619c-0.369,-0.413 -0.553,-1.209 -0.553,-2.389l2.345,-0.487l-0,1.349l8.074,0l-0,-2.61l-7.123,0c-1.077,0 -1.818,-0.184 -2.223,-0.553c-0.406,-0.368 -0.609,-1.084 -0.609,-2.145l0,-1.261c0,-1.062 0.203,-1.777 0.609,-2.146c0.405,-0.369 1.146,-0.553 2.223,-0.553l6.481,0c1.091,0 1.836,0.188 2.234,0.564c0.398,0.376 0.597,1.102 0.597,2.179l-2.344,0.553l-0,-1.305l-7.477,-0l0,2.455l7.123,0c1.076,0 1.821,0.185 2.234,0.553c0.413,0.369 0.619,1.084 0.619,2.146l0,1.57Z" style="fill:#fff;fill-rule:nonzero;"/>
  <path class="symbol-15" d="M234.289,13.822l2.952,8.113c-0,0 0.147,0.431 0.861,0.397c0,0 11.102,-0.615 15.199,-1.097c3.162,-0.373 4.51,-0.305 9.381,-1.799c-0,0 1.771,-3.2 1.3,-8.901l1.107,0.249c0,0 0.139,-1.632 -0.028,-3.569c0,-0 1.192,0.042 1.55,0.442c0.503,0.563 1.102,2.025 1.467,2.934c0.325,0.81 0.599,1.653 0.719,2.518c0.129,0.932 0.111,2.186 0.056,3.072c-0.048,0.756 -0.388,2.241 -0.388,2.241c0,0 10.682,-0.443 16.964,4.262c-0,-0 0.027,0.138 -0.111,0.47c-0,0 -6.395,3.263 -25.074,1.084c0,0 -25.672,-0.524 -26.431,-0.456c-0,0 -0.544,0.726 -1.077,0c0,0 -3.842,-0.408 -3.82,-0.691l0.295,-9.188c-0,0 1.588,-0.738 5.078,-0.081Z" style="fill:#fff;"/>
  </svg>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  name: 'PreloaderWeb',
  props: {
    enable: Boolean
  },
  mounted() {
      this.$emit('play');
  }
}
</script>

<style scoped>

.preloader {
  -webkit-animation: slit-out-horizontal 0.5s ease-in both 6s;
  animation: slit-out-horizontal 0.5s ease-in both 6s;
  position: fixed;
  z-index:6000;
  width: 100%;
  height: 100%;
  background-color: black;
}

.preloader-plane {
  transform: scale(0.15) translate(-409%, 100%);
  animation: plane 2.8s ease-out 3s forwards, zoomin 1s ease calc(3.5s + 2.4s) forwards;


}

.preloader-logo {
  /*transform: translate(calc(50% - calc(580px / 2)), calc(50vh - 55%));
*/
transform: scale(1);
/* animation: zoomin 1s ease calc(3.5s + 2.4s) forwards;*/
display: flex;
justify-content: center;
align-items: center;
height: 100%;
    width: 100%;
}
.preloader-logo div {
  width: 500px;
}
.preloader-text {
  position: fixed;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%) scale(1,1);
  
  transition: all ease;
  opacity: 1;
  animation: slide 1s ease forwards;
  animation-delay: 2.5s;
  pointer-events: none;
}

.text-line {
  stroke-dasharray: 500;
  opacity: 0;
  stroke-dashoffset: 500;
  animation: opacita 0.8s ease forwards, dash 2s ease-in forwards, filling 1.8s ease-in forwards;
  font-size: 70px;
  font-family: 'D-DIN Exp DINExp-Bold', Arial, sans-serif;
  font-weight: 900;
  color: transparent;
}

.text-line text {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.symbol-1 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1s);
}
.symbol-2 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1.1s);
}

.symbol-3 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1.2s);
}

.symbol-4 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1.3s);
}
.symbol-5 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1.4s);
}
.symbol-6 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1.5s);
}
.symbol-7 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1.6s);
}
.symbol-8 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1.7s);
}
.symbol-9 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1.8s);
}
.symbol-10 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 1.9s);
}
.symbol-11 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 2s);
}
.symbol-12 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 2.1s);
}
.symbol-13 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 2.2s);
}
.symbol-14 {
  opacity:0;
  animation: opacita 0.5s linear forwards;
  animation-delay: calc(2.5s + 2.3s);
}
.symbol-15 {
  opacity:0;
  animation: slide-in-blurred-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both; 
  animation-delay: calc(2.5s + 2.4s) ;

}

@keyframes slide-in-blurred-left {
  0% {
    transform: translateX(-1000px) scaleX(1) scaleY(1);
    transform-origin: 100% 50%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes zoomin {

  to {
   transform: scale(500) translate(10px, 0%);

  }
}

@keyframes slide {
  to {
      transform: translate(calc(15% - calc(580px / 2)), -75%) scale(0.4, 0.4);
    opacity: 00;

  }
}

@keyframes plane {
 38% {
    transform: scale(0.15) translate(-60%, 100%) rotate(-0deg)
  }
 30% {
    transform: scale(0.15) translate(-50%, 81%) rotate(-5deg)
  }
  32% {
    transform: scale(0.15) translate(-20%, 61%) rotate(-10deg)
  }
  34% {
    transform: scale(0.15) translate(0%, 41%) rotate(-15deg)
  }
36% {
    transform: scale(0.15) translate(20%, 21%) rotate(-18deg)
  }
  38% {
    transform: scale(0.15) translate(40%, 11%) rotate(-20deg)
  }
  40% {
    transform: scale(0.15) translate(60%, 1%) rotate(-25deg)
  }
  42% {
    transform: scale(0.15) translate(80%, -11%) rotate(-28deg)
  }
  44% {
    transform: scale(0.15) translate(100%, -31%) rotate(-30deg)
  }
46% {
    transform: scale(0.15) translate(120%, -41%) rotate(-32deg)
  }
  49% {
    transform: scale(0.15) translate(150%, -51%) rotate(-35deg)
  }
  100% {
   transform: scale(0.15) translate(172%, -61%) rotate(-37deg)
  }
}
@keyframes opacita {
  to {
    opacity: 1;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes filling {

  0%,
  90% {
    fill: white;
    fill-opacity: 0;
  }

  100% {
    fill: white;
    fill-opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-8-16 8:22:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slit-out-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes slit-out-horizontal {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
            transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
            transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
}
@keyframes slit-out-horizontal {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
            transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
            transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
}

</style>
