<template>
  <footer>
    <div class="container">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <img src="../assets/menu_logo.svg" class="logo">
        </div>
        <div class="column"></div>
        <div class="column is-narrow">
          (c) 2023 Unpredictables
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'NavbarMain',
    props: {
      enable: Boolean
    }
  }
</script>

<style lang="scss" scoped>
footer {
  padding: 50px;
  background-color: black;
  width: 100%;
  .logo {
    width: 250px;
  }
}
</style>
