import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../views/MainPage.vue'
import Article from '../views/Article.vue'
import MoazagotlView from '../views/MoazagotlView.vue'
import MikeMike from '../views/MikeMike.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Domov',
    component: MainPage
  },
  {
    path: '/moazagotl',
    name: 'MoazagotlView',
    component: MoazagotlView
  },
  {
    path: '/mike-mike',
    name: 'MikeMike',
    component: MikeMike
  },
  {
    path: '/clanek/:slug',
    name: 'Article',
    component: Article,
    props: (route) => ({slug: route.params.slug}),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
