<template>
  <div class="UnPr_Navbar">
   <div class="UnPrNb_Wrapper">
     <div class="columns is-mobile">
        <router-link to="/mike-mike" tag="div" class="column is-narrow">
          <div class="UnPrNb_MenuButton">
            <div class="UnPrNb_MenuIcon"></div>
            <div class="UnPrNb_MenuDescription">MIKE-MIKE</div>

          </div>


        </router-link>
        <div class="column"></div>
        <div class="column is-narrow">
         <router-link to="/"> <div class="UnPrNb_MenuButton">
          <div class="UnPrNb_MenuLogoD is-hidden-mobile"></div>
          <div class="UnPrNb_MenuLogoM is-hidden-tablet"></div>
          </div></router-link>
        </div>
        <div class="column"></div>
        <router-link to="/moazagotl" tag="div" class="column is-narrow">
          <div class="UnPrNb_MenuButton">
            <div class="UnPrNb_MenuSnezka"></div>
            <div class="UnPrNb_MenuDescription">MOAZAGOTL</div>
          </div>

        </router-link>
      </div>
   </div>
  </div>
</template>

<script>
  export default {
    name: 'NavbarMain',
    props: {
      enable: Boolean
    }
  }
</script>

<style scoped>
  .UnPrNb_MenuButton {
    text-align: center;
    display:flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    padding: 25px;
  }
  .UnPrNb_Wrapper
  {
        margin-left: 50px;
    margin-right:50px;
  }

  .UnPrNb_MenuDescription {
    font-family: 'D-DIN Regular', Arial, sans-serif;
    font-size: .8rem;
    margin-top: -20px;
    opacity: 0;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .UnPrNb_MenuButton:hover .UnPrNb_MenuDescription {
    margin-top: -16px;
    margin-bottom: -4px;
    opacity: .5;
  }

  .UnPr_Navbar {
    margin: 0 auto;
    margin-top: 8px;
    width: 100%;
    z-index: 900;
    position:fixed;
  }

  .UnPrNb_MenuIcon {
    width: 56px;
    height: 50px;
    background-image: url(../assets/menu_icon.svg);
    background-repeat: no-repeat;
  }

  .UnPrNb_MenuLogoD {
    width: 350px;
    height: 50px;
    background-image: url(../assets/menu_logo.svg);
    background-repeat: no-repeat;
  }

  .UnPrNb_MenuLogoM {
    width: 50px;
    height: 50px;
    background-image: url(../assets/menu_logo_mobile.svg);
    background-repeat: no-repeat;
  }

  .UnPrNb_MenuSnezka {
    width: 40px;
    height: 50px;
    background-image: url(../assets/snezka.svg);
    background-repeat: no-repeat;
  }
</style>