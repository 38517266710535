<template>
    <div>
        <div class="UnPr_Article">
            <div class="UnPrAr_HeaderContainer">
                <div class="UnPrAr_HeaderShadow">
                    <svg height="300" stroke="white" stroke-width="1" class="text-line" width="100%"><text x="50%"
                            dominant-baseline="middle" text-anchor="middle" y="50%">Mike-Mike</text></svg>
                </div>
            </div>
        </div>

        <div class="UnMm-floated">
            <div class="UnMm-title">
                Nimbus 4DM
            </div>
            <div class="UnMm-subtitle">
                : Náš mazlík
            </div>

        </div>

        <div class="wrapper-images">
            <div class="images-line">
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_229916bd.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_c904f729.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_d33141a6.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_e5f0606a.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_f00e225b.jpg'/>
                </div>
            </div>
            <div class="images-line">
                
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_e5f0606a.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_f00e225b.jpg'/>
                </div>
                <div class="line">
                        <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_229916bd.jpg'/>
                    </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_c904f729.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_d33141a6.jpg'/>
                </div>
                
            </div>
            <div class="images-line">
                
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_d33141a6.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_e5f0606a.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_229916bd.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_c904f729.jpg'/>
                </div>
                <div class="line">
                    <img class="img" src='../assets/mmphotos/Obrázek WhatsApp, 2023-10-29 v 14.24.39_f00e225b.jpg'/>
                </div>
            </div>
            
        </div>
        <footer-main></footer-main>
    </div>
</template>

<style scoped lang="scss">
    .UnPrAr_HeaderContainer {
        position: relative;
        margin-top: -55px;
        margin-bottom: -55px;

    }

    :root {
        --duration: 15s;
    }

    .wrapper-images {
        display: flex;
        flex-direction: column;
        height: 150vh;
        justify-content: center;
        left: 50%;
        z-index: -1;
        position: absolute;
        top: 50%;
        opacity: 0.8;
        transform: translate3d(-50%, -50%, 0) perspective(800px) rotatey(10deg) rotateX(-10deg);
    }

    .UnMm-floated
    {
        height: 500px;
        margin:50px;
        display: flex;
        gap: 20px;
        .UnMm-title
        {
            font-size: 5rem;
            font-weight: bold;
        }
        .UnMm-subtitle {
            font-size: 5rem;opacity:.4;

            font-weight: bold;
        }
    }

    .images-line {
        animation: runner var(--duration) linear infinite;
        display: flex;
        transform: translateX(23%);

        &:nth-child(even) {
            animation-duration: calc(var(--duration) * 1.5);
        }

        .line {
            background-position: 50% 50%;
            background-size: cover;
            flex: none;
            height: 29vh;
            margin: clamp(10px, 8vw, 20px);
            width: 50vh;
            position: relative;
            transition: ease-in-out all 0.3s;
            border-radius: 1rem;

            .img {
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                
                opacity: .5;
                top: 0;
                left: 0;
                background-position: 50% 50%;
                background-size: cover;
                transition: ease-in-out all 0.3s;
                overflow: hidden;
                border-radius: 1rem;
            }

            &:hover {
                .img {
                    transform: scale(.95) translatez(0px);
                }

                &:after {
                    filter: blur(35px) opacity(0.8);
                }
            }

            &:after {
                content: "";
                background: inherit;
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                top: 3vh;
                position: absolute;
                background-size: cover;
                z-index: -1;
                transition: ease-in-out all 0.3s;
                filter: blur(25px) opacity(0.8);
            }
        }
    }

    @keyframes runner {
        to {
            transform: translateX(-10.4%);
        }
    }

    .UnPrAr_HeaderShadow {
        font-size: 7rem;
        opacity: .2;
        margin-left: -4rem;
        margin-right: -4rem;
        stroke-dasharray: 200;
        stroke-dashoffset: 500;
        color: transparent;
        animation: dash 25s linear infinite;
        fill: transparent;
        font-family: 'D-DIN Exp DINExp-Bold', Arial, sans-serif;

    }

    @keyframes dash {
        0% {
            stroke-dashoffset: 500;
        }

        50% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: 500;
        }
    }

    .red {
        color: red;
    }

    .UnPr_Article {
        max-width: 1400px;
        margin: 0 auto;
        padding: 25px;
        padding-top: 100px;

        .A {
            padding-top: 20px;
        }
    }

    .UnPrAr_Lvl1 {
        opacity: .5;
    }
</style>

<script>
    import FooterMain from '@/components/FooterMain.vue';
    export default {
        name: 'veShopCategoryView',
        components: {
            FooterMain
        },
        props: {},
        title: "",
        data() {
            return {

            }
        },
        methods: {

        },
        mounted() {}
    }
</script>